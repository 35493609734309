import React, { useContext, useState } from "react";
//import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import RouterContext from "../../context/RouterContext";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from '@material-ui/core/';
import { ListItemText, ListItemIcon } from '@material-ui/core/';
import * as Icons from '@material-ui/icons/'
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { AiFillWindows } from "react-icons/ai";
import {properties} from "utils/properties";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import axios from 'axios';

const ColoredSwitch = withStyles({
    switchBase: {
        color: "#808080",  //grey if unchecked
        '&$checked': {
            color: "#30720f",   //neutral color
        },
        '&$checked + $track': {
            backgroundColor: "#30720f",   //neutral color
        },
    },
    checked: {},
    track: {},
})(Switch);



const AccordionSummary = withStyles({
    root: {
      flexDirection: "row"
    },
    expandIcon:{
        background:"#FFFFFF !important",
        //color:"black"
    }

  })(MuiAccordionSummary);

  const useStyles = makeStyles(theme => ({
    paper: {
        position: "absolute",
        width: 400,
        height: "100%",
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none"
    },
    button: {
        border: "none",
        cursor: "pointer",
        margin: "0.3125rem 1px",
        padding: "12px 30px",
        position: "relative",
        fontSize: "12px",
        minWidth: "auto",
        minHeight: "auto",
        textAlign: "center",
        transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        fontWeight: "400",
        lineHeight: "1.42857143",
        whiteSpace: "nowrap",
        willChange: "box-shadow, transform",
        touchAction: "manipulation",
        borderRadius: "3px",
        letterSpacing: "0",
        textTransform: "uppercase",
        verticalAlign: "middle",
        color: "white",
        textDecoration: "none"
      },
      cancel: {
        //boxShadow: "0 14px 26px -12px rgb(244 67 54 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(244 67 54 / 20%)",
        backgroundColor: "#e6e6e6",
        color:"#000000"
      },
      success: {
        backgroundColor: "#4caf50",
        color:"#000000"
      }
    }));



export default function Header() {
    //const history = useHistory();
    const navigate = useNavigate(); 
    const location = useLocation();
    const { pageRouter, setPageRouter } = useContext(RouterContext);
    const [open, setOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [modalData, setData] = useState();
    const [alert, setAlert] = useState(null);
    const [menuCommandsComitato, setMenuCommandsComitato] = useState();
    const [menuCommandsConsiglio, setMenuCommandsConsiglio] = useState();
    const [menuCommandsGeneral, setMenuCommandsGeneral] = useState();
    const [menuCommandsCollegio, setMenuCommandsCollegio] = useState();
    //COMMISSIONI 17/08/2023
    const [menuCommandsCommissioni, setmenuCommandsCommissioni] = useState();
    //17/08/2023
    const classes = useStyles();

    const handleConfirm99 = (value) => {
        console.log('Input value:', value);
        var bodyFormData=new FormData();
        bodyFormData.append('idf', value);
        setAlert(false);
        axios.post(properties.productionUrl+'/rest/regenerateIdf', bodyFormData)
            .then(res => {
              console.log(res.data)
              if (res.data.status === 'ok') {
                toast.success("OPERAZIONE ESEGUITA CON SUCCESSO")
              } else {
                toast.success("SI E' VERIFICATO UN ERRORE ESEGUENDO L'OPERAZIONE RICHIESTA")
              }
              //setTimeout(() => {history.push({pathname: "/init",})}, 500)
              setTimeout(() => {navigate("/init")}, 500)
              setAlert(null)
              //handleClose()
            })
            .catch(err => {
              console.log(err);
              setAlert(null);
              //handleClose();
            })


      };


      const confirmRegenerateIdf = () => {
        console.log("CSS handling", classes.button, classes.success, classes.cancel);
        setAlert(
          <SweetAlert
            input
            required
            showCancel
            //confirmBtnBsStyle="primary"
	        //cancelBtnBsStyle="light"
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.cancel}
            confirmBtnText="Conferma"
            cancelBtnText="Annulla"
            validationMsg="Inserisci l'identificativo della seduta per la quale abilitare la gestione degli allegati (e.g. 504)"
            style={{ display: "block", marginTop: "auto", marginBottom: "auto", color: 'black', backgroundColor:'white'}}
            title="Pre-Consolidamento"
            placeHolder="Inserisci l'identificativo della seduta"
            onConfirm={(response) => handleConfirm99(response)}
            onCancel={() => { setAlert(null) }}
          > Inserisci l'identificativo della seduta che vuoi riaprire
          </SweetAlert>
        );
      }


    if (window.cordova)
    {
       var onSuccess = function (canGoBackResult)
       {
          //window.canGoBackResult = canGoBackResult;
          window.canGoBackResult = JSON.parse(canGoBackResult);
       }

       var onFailure = function ()
       {

       }
       window.canGoBack(onSuccess,onFailure);

       var onSuccess = function(response)
       {
         console.log("OK LOGIN 33");
         var obj=JSON.parse(response);
         var ruolo = obj.Ruolo;

         window.c_ruolo = obj.Ruolo;
         window.c_name = obj.Name;
         window.c_genere = obj.Genere;

         window.c_commandsCommissioni = obj.CommandsCommissioni.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute") && !value.label.includes("Gestore dei Verbali");
         });

         window.c_commandsGeneral = obj.CommandsGeneral.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali");
         });

         window.c_commandsComitato = obj.CommandsComitato.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali");
         });

         window.c_commandsConsiglio = obj.CommandsConsiglio.filter(function(value, index, arr){
              return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali");
         });
         window.c_commandsCollegio = obj.CommandsCollegio.filter(function(value, index, arr){
            return value.label != "Gestore delle Sedute" && value.label != "Tutte le Sedute" && value.label != "Preview Scheda" && value.label != "Preview Fascicolo" && !value.label.includes("Gestore delle Sedute")&& !value.label.includes("Gestore dei Verbali");
       });

       }

       var onError = function() {}

       window.getInfo3(onSuccess, onError);
    }

    if(localStorage.getItem("new_ui")=== undefined || localStorage.getItem("new_ui")=== null)
        localStorage.setItem("new_ui", false);

    const [checked, setChecked] =useState(stringToBoolean(localStorage.getItem("new_ui")));
    let temp="";
    let color123="#30720f";
    //console.log("HHHH-1",temp, pageRouter.router, pageRouter.router.indexOf("/fascicoli/"));
    //if(pageRouter.router.indexOf("/fascicoli/")!=-1){
    if(location.pathname.indexOf("/fascicoli/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/fascicoli/")+11,pageRouter.router.indexOf("/fascicoli/")+19);
        console.log("TEST",temp, temp.length);
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Comitato di Gestione - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Comitato di Gestione";
    }
    else if(location.pathname.indexOf("/consiglio/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/consiglio/")+11,pageRouter.router.indexOf("/consiglio/")+19);
        //console.log("TEST",temp, temp.length);
        color123="#BF360C";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Consiglio Generale - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Consiglio Generale";
    }
    else if(location.pathname.indexOf("/collegio/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/collegio/")+11,pageRouter.router.indexOf("/collegio")+19);
        //console.log("TEST",temp, temp.length);
        color123="#666999";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Collegio Sindacale - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Collegio Sindacale";
    }
    //COMMISSIONI 17/08/2023
    else if(location.pathname.indexOf("/cg_pianeta/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_pianeta/")+11,pageRouter.router.indexOf("/cg_pianeta/")+19);
        //console.log("TEST",temp, temp.length);
        color123="#000000";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Commissione Pianeta - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Commissione Pianeta";
    }
    else if(location.pathname==="/calendar_cg_pianeta"){
        temp="Commissione Pianeta";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/folders101")!=-1){
        temp="Commissione Pianeta";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/files101")!=-1){
        temp="Commissione Pianeta";
        color123="#000000";
        }

    //SIMILMENTE PER LE ALTRE
    else if(location.pathname.indexOf("/cg_cultura/")!=-1){
        temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_cultura/")+11,pageRouter.router.indexOf("/cg_cultura/")+19);
        //console.log("TEST",temp, temp.length);
        color123="#000000";
        if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
            temp= "Commissione Cultura - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
        else
            temp="Commissione Cultura";
    }
    else if(location.pathname==="/calendar_cg_cultura"){
        temp="Commissione Cultura";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/folders102")!=-1){
        temp="Commissione Cultura";
        color123="#000000";
    }
    else if(location.pathname.indexOf("/files102")!=-1){
        temp="Commissione Cultura";
        color123="#000000";
        }

    else if(location.pathname.indexOf("/cg_persone/")!=-1){
            temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_persone/")+11,pageRouter.router.indexOf("/cg_persone/")+19);
            //console.log("TEST",temp, temp.length);
            color123="#000000";
            if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
                temp= "Commissione Persone - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
            else
                temp="Commissione Persone";
        }
        else if(location.pathname==="/calendar_cg_persone"){
            temp="Commissione Persone";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/folders103")!=-1){
            temp="Commissione Persone";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/files103")!=-1){
            temp="Commissione Persone";
            color123="#000000";
        }

    else if(location.pathname.indexOf("/cg_finanza/")!=-1){
            temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_finanza/")+11,pageRouter.router.indexOf("/cg_finanza/")+19);
            //console.log("TEST",temp, temp.length);
            color123="#000000";
            if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
                temp= "Commissione Finanza - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
            else
                temp="Commissione Finanza";
        }
        else if(location.pathname==="/calendar_cg_finanza"){
            temp="Commissione Finanza";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/folders104")!=-1){
            temp="Commissione Finanza";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/files104")!=-1){
            temp="Commissione Finanza";
            color123="#000000";
        }

    else if(location.pathname.indexOf("/cg_statuto/")!=-1){
            temp = pageRouter.router.substring(pageRouter.router.indexOf("/cg_statuto/")+11,pageRouter.router.indexOf("/cg_statuto/")+19);
            //console.log("TEST",temp, temp.length);
            color123="#000000";
            if(temp.length!=0&& temp.indexOf("/")<0&& temp.length==8)
                temp= "Commissione Statuto - "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
            else
                temp="Commissione Statuto";
        }
        else if(location.pathname==="/calendar_cg_statuto"){
            temp="Commissione Statuto";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/folders105")!=-1){
            temp="Commissione Statuto";
            color123="#000000";
        }
        else if(location.pathname.indexOf("/files105")!=-1){
            temp="Commissione Statuto";
            color123="#000000";
        }
    //17/08/2023
    //else if(pageRouter.router.indexOf("/schede/")!=-1){
    else if(location.pathname.indexOf("/schede/")!=-1){
       //temp= "- scheda "+;
       //temp ="- Scheda "+ pageRouter.router.substring(pageRouter.router.indexOf("/schede/")+8);
       //console.log("QQQ",pageRouter.router.indexOf("/schede/"));
       var initString=pageRouter.router.substring(8);
       //console.log("QQ1",initString);
       var separator=initString.indexOf("/");
       //console.log("QQ2",separator);
       //console.log("QQ3",initString.substring(0,separator));
       var tbd="";
       if (initString.length >0)
            tbd =initString;
       if (separator !==-1) {
            tbd= initString.substring(0,separator);
       }
       temp ="Comitato di Gestione - Scheda "+ tbd;

       //pageRouter.router.lastIndex("/");
    }
    else if(location.pathname.indexOf("/verbaliConsiglio")!=-1 && location.pathname.indexOf("preview/verbaliConsiglio")==-1){
        temp="Consiglio Generale - Verbali Approvati";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/verbaliCollegio")!=-1 && location.pathname.indexOf("preview/verbaliCollegio")==-1){
        temp="Collegio Sindacale - Verbali Approvati";
        color123="#666999";
    }
    else if(location.pathname.indexOf("/verbali")!=-1 && location.pathname.indexOf("preview/verbali")==-1)
        temp="Comitato di Gestione - Verbali Approvati";
    else if(location.pathname.indexOf("/presenze2")!=-1){
        temp="Consiglio Generale - Presenze";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/presenze3")!=-1){
        temp="Collegio Sindacale - Presenze";
        color123="#666999";
    }
    else if(location.pathname.indexOf("/presenze")!=-1)
        temp="Comitato di Gestione - Presenze";
    else if(location.pathname.indexOf("/folders3")!=-1){
            temp="Collegio Sindacale";
            color123="#666999";
        }
    else if(location.pathname.indexOf("/folders2")!=-1){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/folders")!=-1)
        temp="Comitato di Gestione";
    else if(location.pathname.indexOf("/files3")!=-1){
        temp="Collegio Sindacale";
        color123="#666999";
        }
    else if(location.pathname.indexOf("/files2")!=-1){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname.indexOf("/files")!=-1)
        temp="Comitato di Gestione";
    else if(location.pathname==="/consiglio"){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname==="/collegio"){
        temp="Collegio Sindacale";
        color123="#666999";
    }
    else if(location.pathname==="/cg_pianeta"){
        temp="Commissione Pianeta";
        color123="#000000";
    }
    else if(location.pathname==="/cg_cultura"){
        temp="Commissione Cultura";
        color123="#000000";
    }
    else if(location.pathname==="/cg_persone"){
        temp="Commissione Persone";
        color123="#000000";
    }
    else if(location.pathname==="/cg_finanza"){
        temp="Commissione Finanza";
        color123="#000000";
    }
    else if(location.pathname==="/cg_statuto"){
        temp="Commissione Statuto";
        color123="#000000";
    }
    else if(location.pathname==="/fascicoli")
        temp="Comitato di Gestione";
    else if(location.pathname==="/calendarConsiglio"){
        temp="Consiglio Generale";
        color123="#BF360C";
    }
    else if(location.pathname==="/calendarCollegio"){
        temp="Collegio Sindacale";
        color123="#666999";
    }
    else if(location.pathname==="/calendar")
        temp="Comitato di Gestione";
    else if(location.pathname==="/calendarAll")
        temp="Tutti gli Organi";
    else if(location.pathname==="/sedute")
        temp="Tutti gli Organi";


    //console.log("HHHH-2",temp, pageRouter, window.navigation, location, history);
    //console.log("PPP",pageRouter.router.indexOf("/fascicoli/"));
    //console.log("PPP",temp);
    //if(temp)
    //    temp= "- "+ temp.substring(0,2)+ "/"+ temp.substring(2,4)+ "/"+ temp.substring(4,8);
    //console.log("QQQ",temp);
    function stringToBoolean(string){
    switch(string.toLowerCase().trim()){
        case "true":
        case "yes":
        case "1":
          return true;

        case "false":
        case "no":
        case "0":
        case null:
          return false;

        default:
          return Boolean(string);
    }
}



    const prevPage = () => {
        //console.log("RR",history.goBack());
        console.log("LOCATION",location, document.referrer,document);
        //history.goBack();
        navigate(-1);        
        //console.log("BACK",navigate(-1));
    };

    const meetingArgument = () => {
        //console.log("QUI ORA");

        if (window.cordova)
        {
            window.home();
        }
        else
        {
          /* if (checked){
          history.push({
              pathname: "/calendar",
          });   }
          else {
          history.push({
              pathname: "/fascicoli",
          });   }
          */
          /*history.push({
            pathname: "/init",
        });*/
        navigate("/init");
        }

    };

    function getModalStyle() {
        const top = 10;
        const left = 100;
        return {
            top: `${top}%`,
            left: `${left}%`,
            //maxHeight: 'calc(100vh - 210px)',
            //transform: `translate(-${top}%, -${left}%)`
            transform: `translate(-${left}%, -${top}%)`
        };
    }

    const handleOpen = () => {
    if(localStorage.getItem("name")=== null && !window.cordova)
    {
        //console.log("TBI",localStorage.getItem("name"))
        doLogOut();
    }
    else{
        //console.log("wer",localStorage.getItem("name"),localStorage.getItem("genere"));

    var aa={
      //title: "Settings",
      Info: "Benvenut"
    };

    if(localStorage.getItem("name")!== null){
        //console.log("INFO",localStorage.getItem("genere"),localStorage.getItem("name"));
        if(localStorage.getItem("genere")== null ||localStorage.getItem("genere")=="1")
            aa.Info=aa.Info + "o "+ localStorage.getItem("name");
        else
            aa.Info=aa.Info + "a "+ localStorage.getItem("name");
        setData(aa);
        //console.log("P", aa);
    }
    else {
      if (window.c_genere == null || window.c_genere == "1")
        aa.Info=aa.Info + "o " + window.c_name;
      else
        aa.Info=aa.Info + "o " + window.c_name;
      setData(aa);
    }
    //COMMISSIONI 17/08/2023
    if(localStorage.getItem("commandsCommissioni")!== null){
        setmenuCommandsCommissioni(JSON.parse(localStorage.getItem("commandsCommissioni")));
    }
    else if (!window.cordova){
        setmenuCommandsCommissioni(menuCommandsFake);
    }
    else {
        setmenuCommandsCommissioni(window.c_commandsCommissioni);
    }
    //17/08/2023
    if(localStorage.getItem("commandsConsiglio")!== null){
        setMenuCommandsConsiglio(JSON.parse(localStorage.getItem("commandsConsiglio")));
    }
    else if (!window.cordova){
        setMenuCommandsConsiglio(menuCommandsFake);
    }
    else {
        setMenuCommandsConsiglio(window.c_commandsConsiglio);
    }
    if(localStorage.getItem("commandsCollegio")!== null){
        setMenuCommandsCollegio(JSON.parse(localStorage.getItem("commandsCollegio")));
    }
    else if (!window.cordova){
        setMenuCommandsCollegio(menuCommandsFake);
    }
    else {
        setMenuCommandsCollegio(window.c_commandsCollegio);
    }

    if(localStorage.getItem("commandsComitato")!== null){
        //console.log("OK17", localStorage.getItem("commands"));
        //console.log("OK17",JSON.parse(localStorage.getItem("commands")));
        setMenuCommandsComitato(JSON.parse(localStorage.getItem("commandsComitato")));
    }
    else if (!window.cordova){
        //console.log("OK18", menuCommandsFake);
        setMenuCommandsComitato(menuCommandsFake);
    }
    else {
        setMenuCommandsComitato(window.c_commandsComitato);
    }
    if(localStorage.getItem("commandsGeneral")!== null){
        //console.log("OK17", localStorage.getItem("commands"));
        //console.log("OK17",JSON.parse(localStorage.getItem("commands")));
        setMenuCommandsGeneral(JSON.parse(localStorage.getItem("commandsGeneral")));
    }
    else if (!window.cordova){
        //console.log("OK18", menuCommandsFake);
        setMenuCommandsGeneral(menuCommandsFake);
    }
    else {
        setMenuCommandsGeneral(window.c_commandsGeneral);
    }
    setOpen(true);
    }
    };

    const doAction = (item) => {
        //console.log("DA", item);
        //console.log("DA2", item.order);
        //console.log("DA3",window.location.href);
        //console.log("DA3",window.location);
        var dd="";
        if(window.location.pathname.startsWith("/fascicoli/")){
            //console.log(window.location.pathname);
            dd=window.location.pathname.substring(11);
            if(dd.includes("/")){
                    dd=dd.substring(0,dd.indexOf("/"));
                //    console.log("DA4",window.location.pathname,dd);
                }
            else{
                //dd is already ok
                //console.log("DA5",window.location.pathname,dd);
            }
        }

        if((item.label==="Log out")||(item.label==="Logout"))
            doLogOut();
        else{
            if(item.label==="Chiudi pannello")
                handleClose();
            else{
                if(item.label==="Modifica Password"){

                    if (window.cordova)
                    {
                        window.modifyPassword();
                    }
                    else {
                        /*history.push({
                            pathname: "/changePassword",
                        });*/
                        navigate("/changePassword");
                    }
                    handleClose();
                }
                else{
                    if(item.label==="Preview Scheda"){
                        /*history.push({
                        pathname: "/editor",
                        });*/
                        navigate("/editor");
                        handleClose();
                        //alert("Funzionalità ancora da integrare");
                    }
                    else{
                        if(item.label==="Gestisci Pratiche"){
                            /*history.push({
                            pathname: "/raw/last",
                            });*/
                            navigate("/raw/last");
                            handleClose();
                            //alert("Funzionalità ancora da integrare");
                        }
                        else{
                            if(item.label==="Gestisci Ambiti"){
                                /*history.push({
                                pathname: "/updatingAmbiti/last",
                                });*/
                                navigate("/updatingAmbiti/last");

                                handleClose();
                                //alert("Funzionalità ancora da integrare");
                            }
                            else{
                                if(item.label==="Gestisci Allegati"){
                                    /*history.push({
                                    pathname: "/updating/last",
                                    });*/
                                    navigate("/updating/last");
                                    handleClose();
                                    //alert("Funzionalità ancora da integrare");
                                }
                                else{
                                    if(item.label==="Rigenera Seduta"){
                                        /*history.push({
                                        pathname: "/rigenera/last",
                                        });*/
                                        navigate("/rigenera/last");
                                        handleClose();
                                        //alert("Funzionalità ancora da integrare");
                                    }
                                    else{
                                        if(item.label==="Preview Fascicolo"){
                                            /*history.push({
                                            pathname: "/fascicoli/last",
                                            });*/
                                            navigate("/fascicoli/last");
                                            handleClose();
                                            //alert("Funzionalità ancora da integrare");
                                        }
                                        else{
                                            if(item.label==="Sedute Consolidate"){                                                
                                                if(item.action.includes("fascicoli")){
                                                    /*history.push({
                                                        pathname: "/fascicoli",
                                                    }); */
                                                    if(localStorage.getItem("new_ui")==="true")
                                                        navigate("/calendar");
                                                    else
                                                        navigate("/fascicoli");
                                                }
                                                else if(item.action.includes("consiglio")){
                                                  /*history.push({
                                                      pathname: "/consiglio",
                                                  });*/
                                                  if(localStorage.getItem("new_ui")==="true")
                                                    navigate("/calendarConsiglio");
                                                  else
                                                    navigate("/consiglio");
                                                }
                                                else if(item.action.includes("collegio")){
                                                    /*history.push({
                                                        pathname: "/collegio",
                                                    });*/
                                                    if(localStorage.getItem("new_ui")==="true")
                                                    navigate("/calendarCollegio");
                                                  else
                                                    navigate("/collegio");
                                                  }
                                                handleClose();
                                            }
                                            else{
                                                if(item.label==="Gestore delle Sedute"){
                                                    if(item.order >=50){ //Collegio
                                                        /*history.push({
                                                            pathname: "/folders3",
                                                            }); */
                                                            navigate("/folders3");
                                                    }
                                                    else if(item.order >=30){ //Consiglio
                                                    /*history.push({
                                                        pathname: "/folders2",
                                                        });*/
                                                        navigate("/folders2");
                                                    }
                                                    else{
                                                        /*history.push({
                                                            pathname: "/folders",
                                                            });*/
                                                            navigate("/folders");
                                                    }
                                                    handleClose();
                                                    //alert("Funzionalità ancora da integrare");
                                                }
                                                else{
                                                    if(item.label==="Gestore dei File"){
                                                        /*history.push({
                                                        pathname: "/files",
                                                        //pathname: "/fileMgr",
                                                        });*/
                                                        navigate("/files");
                                                        handleClose();
                                                        //alert("Funzionalità ancora da integrare");
                                                    }
                                                    else{
                                                        if(item.label==="Scarica Allegati"){
                                                          /*  history.push({
                                                            pathname: "/scaricaAllegati/last",
                                                            });  */
                                                            navigate("/scaricaAllegati/last");
                                                            handleClose();
                                                            //alert("Funzionalità ancora da integrare");
                                                        }
                                                        else{
                                                            if(item.label==="Blocca/Sblocca"||item.label==="Blocca"||item.label==="Sblocca"){
                                                                    /*history.push({
                                                                    pathname: "/blocca",
                                                                    });*/
                                                                    navigate("/blocca");
                                                                    handleClose();
                                                                    //alert("Funzionalità ancora da integrare");
                                                                }
                                                                else{
                                                                    if(item.label==="Consolida"){
                                                                        /*history.push({
                                                                        pathname: "/consolida",
                                                                        }); */
                                                                        navigate("/consolida");
                                                                        handleClose();
                                                                        //alert("Funzionalità ancora da integrare");
                                                                    }
                                                                    else{
                                                                        if(item.label==="Gestore delle Presenze"){
                                                                            if(item.order >=50){ //Collegio
                                                                                if(dd!=""){
                                                                                    /*history.push({
                                                                                        pathname: "/presenze3/" + dd ,
                                                                                    });*/
                                                                                    navigate("/presenze3/"+dd);
                                                                                }
                                                                                else{
                                                                                    /*history.push({
                                                                                        pathname: "/presenze3/current",
                                                                                    });*/
                                                                                    navigate("/presenze3/current");
                                                                                }
                                                                            }
                                                                            else if(item.order >=30){ //Consiglio
                                                                                if(dd!=""){
                                                                                    /*history.push({
                                                                                        pathname: "/presenze2/" + dd ,
                                                                                    });*/
                                                                                    navigate("/presenze2/"+dd);
                                                                                }
                                                                                else{
                                                                                    /*history.push({
                                                                                        pathname: "/presenze2/current",
                                                                                    });*/
                                                                                    navigate("/presenze2/current");
                                                                                }
                                                                                }
                                                                            else{
                                                                                    if(dd!=""){
                                                                                        /*history.push({
                                                                                            pathname: "/presenze/" + dd ,
                                                                                        });*/
                                                                                        navigate("/presenze/"+dd);
                                                                                    }
                                                                                    else{
                                                                                        /*history.push({
                                                                                            pathname: "/presenze/current",
                                                                                        });*/
                                                                                        navigate("/presenze/current");
                                                                                    }
                                                                            }
                                                                            handleClose();
                                                                        }
                                                                        else{
                                                                            if(item.label==="Cerca"){
                                                                                    /*history.push({
                                                                                        pathname: "/search",
                                                                                    });*/
                                                                                    console.log("Searching...",item.order);
                                                                                    if(item.order >=50){ //Collegio                                                                                        
                                                                                        navigate("/searchCollegio");
                                                                                        }                                                                                                                                                                           
                                                                                    else if(item.order >=30){ //Consiglio                                                                                        
                                                                                        navigate("/searchConsiglio");
                                                                                    }
                                                                                    else
                                                                                        navigate("/search");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Gestore dei Verbali"){
                                                                                    //07/06/2024
                                                                                    if(item.order >=50){ //Collegio                                                                                        
                                                                                        navigate("/verbaliCollegio");
                                                                                        }                                                                                                                                                                           
                                                                                    else if(item.order >=30){ //Consiglio                                                                                        
                                                                                        navigate("/verbaliConsiglio");
                                                                                    }
                                                                                    else
                                                                                        navigate("/verbali");
                                                                                    //07/06/2024
                                                                                    //navigate("/verbali");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Tutte le Sedute"){
                                                                                    /*history.push({
                                                                                        pathname: "/sedute",
                                                                                    });*/
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendarAll");    
                                                                                    else
                                                                                        navigate("/sedute");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Torna a Pre-Consolidamento"){
                                                                                    confirmRegenerateIdf();
                                                                                    //history.push({
                                                                                    //    pathname: "/sedute",
                                                                                    //});
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Pianeta: Sedute Consolidate"){
                                                                                    /*history.push({
                                                                                        pathname: "/cg_pianeta",
                                                                                    });*/
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_pianeta");    
                                                                                    else
                                                                                        navigate("/cg_pianeta");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Pianeta: Gestore delle Sedute"){
                                                                                    /*history.push({
                                                                                        pathname: "/folders101",
                                                                                    });
                                                                                    */
                                                                                    navigate("/folders101");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Cultura: Sedute Consolidate"){
                                                                                    /*history.push({
                                                                                        pathname: "/cg_cultura",
                                                                                    });*/
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_cultura");    
                                                                                    else
                                                                                        navigate("/cg_cultura");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Cultura: Gestore delle Sedute"){
                                                                                    /*history.push({
                                                                                        pathname: "/folders102",
                                                                                    });*/
                                                                                    navigate("/folders102");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Persone: Sedute Consolidate"){
                                                                                    /*history.push({
                                                                                        pathname: "/cg_persone",
                                                                                    });*/
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_persone");    
                                                                                    else
                                                                                        navigate("/cg_persone");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Persone: Gestore delle Sedute"){
                                                                                    /*history.push({
                                                                                        pathname: "/folders103",
                                                                                    });*/
                                                                                    navigate("/folders103");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Finanza: Sedute Consolidate"){
                                                                                    /*history.push({
                                                                                        pathname: "/cg_finanza",
                                                                                    });*/
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_finanza");    
                                                                                    else
                                                                                        navigate("/cg_finanza");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Finanza: Gestore delle Sedute"){
                                                                                    /*history.push({
                                                                                        pathname: "/folders104",
                                                                                    });*/
                                                                                    navigate("/folders104");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Statuto: Sedute Consolidate"){
                                                                                    /*history.push({
                                                                                        pathname: "/cg_statuto",
                                                                                    });*/
                                                                                    if(localStorage.getItem("new_ui")==="true")
                                                                                        navigate("/calendar_cg_statuto");    
                                                                                    else
                                                                                        navigate("/cg_statuto");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                if(item.label==="Statuto: Gestore delle Sedute"){
                                                                                    /*history.push({
                                                                                        pathname: "/folders105",
                                                                                    });*/
                                                                                    navigate("/folders105");
                                                                                    handleClose();
                                                                            }
                                                                            else{
                                                                                alert("Funzionalità ancora da integrare");
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    };


    const handleClose = () => {
    setOpen(false);
    console.log("CON",menuCommandsConsiglio);
    console.log("COM",menuCommandsComitato);
    console.log("COM",menuCommandsCollegio);
    console.log("COM",menuCommandsCommissioni);
    };

    const doLogOut = () => {
        console.log("Logout called");
        localStorage.clear();
        setChecked(false);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', properties.productionUrl + "/rest/logout",true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        xhr.onload = function (e) {
          if (xhr.readyState === 4) {
             if (xhr.status === 200) {
                if (window.cordova)
                {
                  window.pushLogin();
                }
                else {
                  /*history.push({
                      pathname: "/login",
                  });*/
                  navigate("/login");
                }
                //console.log("OK LOGOUT 39");
                handleClose();
             } else {
                console.error("DOLOGOUT - Error 17", xhr.statusText);
                //alert("Logout fallito: Riprovare o contattare l'amministratore");
                if (window.cordova){
                  window.pushLogin();
                }
                else {
                  /*history.push({
                      pathname: "/login",
                  });*/
                  navigate("/login");
                }
             }
          }
        };

        xhr.onerror = function (e) {
          console.error("Error 2", xhr);
        };

        let urlEncodedData = null;
        xhr.send(urlEncodedData);
    }




      const menuCommandsFake = [
    {
      order: 0,
      label: "Sedute Consolidate",
      action:"postIdsf.php",
      icon: 'Notifications'
    },
    {
      order: 1,
      label: "Preview Fascicolo",
      action:"postIdsf.php",
      icon: 'Edit'
    },
    {
      order: 2,
      label: "Gestore delle sedute",
      action:"postIdsf.php",
      icon: 'Notifications'
    },
    {
        order: 4,
        label: "Modifica Password",
        action:"postIdsf.php",
        icon: 'Notifications'
      },
    {
      order: 5,
      label: "Gestore delle Presenze",
      action:"ciao.php",
      icon: 'ListAlt'
    },
    {
      order: 6,
      label: "Chiudi pannello",
      action:"postIdsf.php",
      icon: 'Close'
    },
    {
      order: 7,
      label: "Log out",
      action:properties.productionUrl+"/rest/logout",
      icon: 'Notifications'
    }
    ];


    const menuCommandsComitatoFakeiOS = [
        {
            "order": 1,
            "label": "Sedute Consolidate",
            "action": "https:\/\/eleganza.dev:443\/fascicoli\/",
            "icon": "ViewHeadline"
        },
        {
            "order": 4,
            "label": "Cerca",
            "action": "https:\/\/eleganza.dev:443\/search",
            "icon": "Search"
        },
        {
            "order": 15,
            "label": "Gestore delle Presenze",
            "action": "https:\/\/eleganza.dev:443\/presenze",
            "icon": "ListAlt"
        }
    ];

    const menuCommandsConsiglioFakeiOS = [
        {
            "order": 30,
            "label": "Sedute Consolidate",
            "action": "https:\/\/eleganza.dev:443\/fascicoli\/",
            "icon": "ViewHeadline"
        },
        {
            "order": 31,
            "label": "Cerca",
            "action": "https:\/\/eleganza.dev:443\/search",
            "icon": "Search"
        },
        {
            "order": 32,
            "label": "Gestore delle Presenze",
            "action": "https:\/\/eleganza.dev:443\/presenze2",
            "icon": "ListAlt"
        }
    ];

    const menuCommandsGeneralFakeiOS = [
        {
            "order": 0,
            "label": "Tutte le Sedute",
            "action": "https:\/\/eleganza.dev:443\/sedute",
            "icon": "Group"
        },
        {
            "order": 16,
            "label": "Modifica Password",
            "action": "https:\/\/eleganza.dev:443\/changePassword",
            "icon": "Contacts"
        },
        {
            "order": 17,
            "label": "Logout",
            "action": "https:\/\/eleganza.dev:443\/logout",
            "icon": "Block"
        },
        {
            "order": 18,
            "label": "Chiudi pannello",
            "action": "Chiudi pannello",
            "icon": "Close"
        }
    ];

    const menuCommandsFakeiOS = [
    {
        order: 0,
        label: "Modifica Password",
        action:properties.productionUrl+":443/postIdsf.php",
        icon: 'Notifications'
    },
    {
      order: 2,
      label: "Cerca",

      action:properties.productionUrl+":443/search",
      icon: "Search"
    },
    {
      order: 1,
      label: "Chiudi pannello",
      action:"postIdsf.php",
      icon: 'Close'
    },
    {
      order: 2,
      label: "Log out",


      action:properties.productionUrl+":443/rest/logout",
      icon: 'Notifications'
    }
    ];

    //console.log("WOW",menuCommands);

    const accordionSummaryClick = () => {
        console.log("Accordion Summary Click" );
        //setExpanded(!expanded);
    }

    const CustomModal = () => {
    return modalData ? (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        //scrollable ={true}
      >
        <div style={modalStyle} className={classes.paper}>

          <Typography variant="h6" id="modal-title" style={{
                    color: "#29610d",
                    //color: "#ff0000",
                    fontSize: "23px",
                    fontWeight: "bold",
                }}>
            {modalData.Info}
          </Typography>

          <br />

          <Typography variant="subtitle1" id="simple-modal-description">
            In caso di segnalazioni, contattare l'amministratore via mail (antonio@eleganzadigitale.com) o al seguente numero di telefono (338-6381491). Versione: {properties.version}
          </Typography>
          <br />
          <div className="contentModal2" >

          {menuCommandsGeneral[0].order===0 ?(
              <div
              className="common-list"
              key={menuCommandsGeneral[0]}
              style={{
                          cursor: "pointer",
                          //backgroundColor: "0x00ff00",
                          //color: "0x0000ff",
                      }}
          //onClick={doLogOut}
          onClick={ () =>doAction(menuCommandsGeneral[0])}
          >
               <MenuItem>
        <ListItemIcon >
          {React.createElement(Icons[menuCommandsGeneral[0].icon])}
        </ListItemIcon>
        <ListItemText inset primary={menuCommandsGeneral[0].label} />
          </MenuItem>
          </div>
            ):(
                <></>
            )}

          {menuCommandsComitato.length != 0 ? (
          <Accordion defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Comitato di Gestione
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsComitato.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}
          {menuCommandsConsiglio.length!=0 ? (
          <Accordion defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Consiglio Generale
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsConsiglio.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}

            {menuCommandsCommissioni.length!=0 ? (
            <Accordion defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Commissioni Consiglio Generale
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsCommissioni.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}

            {menuCommandsCollegio.length!=0 ? (
          <Accordion defaultExpanded={true} elevation={3} style={{  background: "#FFFFFF", boxShadow: "unset",  borderBottomRightRadius: "8px", borderTopRightRadius: "8px"}} >
            <AccordionSummary   expandIcon={<ExpandMoreIcon  style={{ background: "#FFFFFF" }}/>} aria-controls="panel1a-content" >
                <Typography component={'div'} className={classes.heading} style={{ paddingLeft: "0px", margin: "0px", width: "100%", fontWeight: "bold"}} onClick={() => accordionSummaryClick()}> Collegio Sindacale
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px", width:"100%" }}  onClick={(event) => event.stopPropagation()}>

            {menuCommandsCollegio.map((item, index) => (
                <div
                    className="common-list"
                    key={index}
                    style={{ cursor: "pointer"}}
                    onClick={ () =>doAction(item)}>
                    <MenuItem>
                        <ListItemIcon >
                            {React.createElement(Icons[item.icon])}
                        </ListItemIcon>
                        <ListItemText inset primary={item.label} />
                    </MenuItem>
                </div>
            ))}
            </AccordionDetails>
            </Accordion>
            ):(<></>)}

            {menuCommandsGeneral.map((item, index) => (

                <div
                    className="common-list"
                    key={index}
                    style={{
                                cursor: "pointer",
                                //backgroundColor: "0x00ff00",
                                //color: "0x0000ff",
                            }}
                //onClick={doLogOut}
                onClick={ () =>doAction(item)}
                >
                {item.order !=0 ? (
                    <MenuItem>
                    <ListItemIcon >
                        {React.createElement(Icons[item.icon])}
                    </ListItemIcon>
                    <ListItemText inset primary={item.label} />
                    </MenuItem>
                ):(
                    <></>
                )
                }

              {/*       </div> */}

                </div>
            ))}
        </div>
          {/* <SimpleModal /> */}
        </div>
      </Modal>
    ) : null;
    };

    //const classes = useStyles();


    const handleChange = (event) => {
        //console.log("QUINTANA",location.pathname);
        if(localStorage.getItem('new_ui')==null)
            setChecked(false);
        else{
            //console.log ("Switch pressed",localStorage.getItem("new_ui"));
            localStorage.setItem("new_ui", event.target.checked);
            //console.log ("Switch pressed",localStorage.getItem("new_ui"));
            //setChecked(stringToBoolean(localStorage.getItem("new_ui")));
            //console.log ("PPPP",window.location.pathname);
            //if (window.location.pathname)
            //console.log("CK",checked);
            if (event.target.checked){
                if(location.pathname=="/sedute"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendarAll",
                    });*/
                    navigate("/calendarAll");
                }
                //COMMISSIONI 17/08/2023
                if(location.pathname=="/cg_pianeta"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_pianeta",
                    });
                    */
                    navigate("/calendar_cg_pianeta");
                }
                if(location.pathname=="/cg_cultura"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_cultura",
                    });*/
                    navigate("/calendar_cg_cultura");
                }
                if(location.pathname=="/cg_persone"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_persone",
                    });*/
                    navigate("/calendar_cg_persone");
                }
                if(location.pathname=="/cg_finanza"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_finanza",
                    });*/
                    navigate("/calendar_cg_finanza");

                }
                if(location.pathname=="/cg_statuto"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_statuto",
                    });*/
                    navigate("/calendar_cg_statuto");
                }
                //17/08/2023
                if(location.pathname=="/consiglio"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendarConsiglio",
                    });*/
                    navigate("/calendarConsiglio");
                }
                if(location.pathname=="/collegio"){
                    /*history.push({
                        pathname: "/calendarCollegio",
                    });*/
                    navigate("/calendarCollegio");
                }
                if(location.pathname=="/fascicoli"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar",
                    });*/
                    navigate("/calendar");
                }
                if(location.pathname.includes("/fascicoli/")){
                    var tmpPath=location.pathname.substring(location.pathname.indexOf("/fascicoli/")+11);
                    if(tmpPath.endsWith("/"))
                        tmpPath=tmpPath.substring(0,tmpPath.indexOf("/"));
                    //console.log("5",tmpPath);
                    //if (tmpPath.length==8){
                    if(!isNaN(tmpPath)){
                        var newPath="/fascicoli/"+tmpPath+"/summary";
                        //console.log("CP2",newPath);
                        /*history.push({
                            pathname: newPath,
                        });*/
                        navigate(newPath);
                    }
                }

            }
            else {
                if(location.pathname=="/calendarAll"){
                    //console.log("3",location.pathname);
                    /*history.push({
                        //pathname: "/fascicoli",
                        pathname: "/sedute",
                    });*/
                    navigate("/sedute");
                }
                if(location.pathname=="/calendar"){
                    //console.log("3",location.pathname);
                    /*history.push({
                        pathname: "/fascicoli",
                    });*/
                    navigate("/fascicoli");
                }
                //COMMISSIONI 17/08/2023
                if(location.pathname=="/calendar_cg_pianeta"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/cg_pianeta",
                    });*/
                    navigate("/cg_pianeta");
                }                
                if(location.pathname=="/calendar_cg_cultura"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_cultura",
                    });*/
                    navigate("/cg_cultura");
                }
                if(location.pathname=="/calendar_cg_persone"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_persone",
                    });*/
                    navigate("/cg_persone");
                }
                if(location.pathname=="/calendar_cg_finanza"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_finanza",
                    });*/
                    navigate("/cg_finanza");

                }
                if(location.pathname=="/calendar_cg_statuto"){
                    //console.log("2",location.pathname);
                    /*history.push({
                        pathname: "/calendar_cg_statuto",
                    });*/
                    navigate("/cg_statuto");
                }
                //17/08/2023
                if(location.pathname=="/calendarConsiglio"){
                    //console.log("3",location.pathname);
                    /*history.push({
                        pathname: "/consiglio",
                    });*/
                    navigate("/consiglio");
                }
                if(location.pathname=="/calendarCollegio"){
                    //console.log("3",location.pathname);
                    /*history.push({
                        pathname: "/collegio",
                    });*/
                    navigate("/collegio");
                }
                if(location.pathname.endsWith("/summary")||location.pathname.endsWith("/summary/")){
                    //console.log("4",location.pathname);
                    var newPath=location.pathname.substring(0, location.pathname.indexOf("/summary"));
                    //console.log("CP",newPath);
                    /*history.push({
                        pathname: newPath,
                    });*/
                    navigate(newPath);

                }

            }

            setChecked(stringToBoolean(localStorage.getItem("new_ui")));
        }
    };

    return (
        <div
            className="header"
            style={{
                //width: "73%",
                //width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                margin: "auto",
                display: "flex",
                //position: "sticky",
                //position: "-webkit-sticky",
                //position: "-moz-sticky",
                //position: "-ms-sticky",
                //position: "-o-sticky",
                position: "sticky",
                zIndex: 100,
                top: 0,
                background: "white",
                justifyContent: "space-between",
            }}
        >
            {2<1 ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        visibility: "hidden",

                    }}
                    onClick={prevPage}
                >
                    <div>
                        <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                            style={{ paddingRight: "10px",  }}
                        ></i>
                    </div>
                    <p className="prev">Indietro</p>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={prevPage}
                >
                    <div>
                        <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                            style={{ paddingRight: "10px", color: "#000000" }}
                        ></i>
                    </div>

                    <p className="prev" style={{ cursor: "pointer", color: "#000000" }}>
                        Indietro
                    </p>
                </div>
            )}
            { color123=='#30720f' ? (
                console.log("colore comitato"),
                <div
                style={{
                    //color: "#ff5a60",
                    color: "#30720f",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "23px",
                    //fontWeight: "bold",
                }}
            >
                {temp}
            </div>
            ):[
                color123=='#666999' ? (
                    console.log("colore collegio"),
                    <div
                    style={{
                        //color: "#ff5a60",
                        color: "#666999",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "23px",
                        //fontWeight: "bold",
                    }}
                    >
                    {temp}
                </div>
                ):[
                    color123=='#000000' ? (
                        console.log("colore commissioni"),
                        <div
                        style={{
                            //color: "#ff5a60",
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "23px",
                        }}>
                        {temp}
                        </div>
                ):(
                    console.log("colore consiglio"),
                    <div
                    style={{
                        //color: "#ff5a60",
                        color: "#BF360C",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "23px",
                    }}>
                    {temp}
                    </div>
            )]]
            }

            <div style={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
        control={<ColoredSwitch sx={{ m: 1 }} checked={checked} onChange={handleChange}/>}
        label="Nuova UX" style={{color: "black", fontFamily: 'Roboto, sans-serif'}}
        />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0px",
                        cursor: "pointer",
                        //borderBottom: "2px solid" + pageRouter.homebordercolor,
                        borderBottom: "2px solid #30720f",

                    }}
                    onClick={meetingArgument}

                >
              {/*  <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
        label=" Nuova UX" /> */}
       {/* <FormControlLabel label= "Nuova UX" control={
        <Switch defaultChecked onChange={handleChange} color="#ff0000"/>}
        />
       */}
                    <div>
                        <i
                            className="fa fa-home"
                            aria-hidden="true"
                            //style={{ color: pageRouter.homecolor }}
                            style={{ color: "#30720f" }}
                        ></i>
                    </div>


                    <p
                        style={{
                            paddingLeft: "10px",
                            //fontWeight: "bold",
                            color: pageRouter.hometitlecolor,
                        }}
                    >
                        Homepage
                    </p>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0px",
                        cursor: "pointer",

                        //borderBottom: "2px solid" + pageRouter.settingbordercolor,
                        borderBottom: "2px solid #30720f",
                    }}
                    onClick={() => {
                        handleOpen();}}
                >
                    <div>
                        <i
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "10px",
                                //color: pageRouter.settingcolor,
                                color: "#30720f",
                            }}
                            className="fa fa-cog"
                            aria-hidden="true"
                        ></i>
                    </div>
                    <p
                        style={{
                            //fontWeight: "bold",
                            //color: pageRouter.settingtitlecolor,
                            color: "#000000",
                        }}
                    >
                        Settings
                    </p>
                </div>
            </div>
            <CustomModal />
            {alert}
        </div>
    );
}
